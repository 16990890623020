import { useUI } from 'src/layout/UI'

import SettingsItem from 'src/@components/settings/SettingsItem'
import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import googlePlay from 'src/assets/google-play.png'
import googlePlayDark from 'src/assets/google-play-dark.png'
import appStore from 'src/assets/app-store.png'
import appStoreDark from 'src/assets/app-store-dark.png'
import ProfilesPage from 'src/profiles/ProfilesPage'
import { DateTime } from 'luxon'

import { useTranslation } from 'react-i18next'
import { Button, Card, Typography, List, Modal, theme, Form, Switch, Collapse, Divider, Input as InputAnt, Checkbox, Tooltip, Radio, notification } from 'antd'
import { useCompanies, useDoctors, useProfile, useSpecialities } from 'src/profiles/ProfilesApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useRef, useState } from 'react'
import FormHeader from 'src/@components/form/FormHeader'
import clsx from 'clsx'
import Label from 'src/@components/Label'
import Input from 'src/@components/form/Input'
import { supabase } from 'src/supabase'
import { useAuth } from 'src/auth/Auth'
import Select from 'src/@components/form/Select'
import SettingsAvatar from 'src/settings/SettingsAvatar'
import logo from 'src/assets/universus.png'
import Avatar from 'src/@components/Avatar'
import Textarea from 'src/@components/form/Textarea'
import CompanyInvoices from 'src/invoices/CompanyInvoices'

const { useToken } = theme
const { Text, Title } = Typography

const userTitle = (d, universus) => {
  const lastName = d.profile.last_name || ''
  const firstName = d.profile.first_name || ''

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
      <Avatar style={{ position: 'relative', right: 6 }} profile={d.profile} />
      <div className='flex flex-col gap-1'>
        {universus && d.company && <span style={{ fontSize: 12, fontWeight: 'bold' }}>{d.company.name}</span>}
        {`${firstName} ${lastName}`}
      </div>
    </div>
  )
}

const userForm = ({ t, disabled, companies, doctors, universus, update, form, type, specialities }) => {
  return <>
    <Form.Item name='avatar' style={{ display: 'none' }}><Input /></Form.Item>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
      <SettingsAvatar formProfile={form} _remove={() => form.setFieldValue('avatar', '')} _update={(avatar) => form.setFieldValue('avatar', avatar)} />
    </div>

    {universus && !update && type !== 'transcribers' && (
      <Label label={"Clinique"} icon={light('hospital')}>
        <Form.Item name='company_id' rules={[{ required: true, message: false }]}>
          <Select
            allowClear
            options={companies.map((item) => ({ label: item.name, value: item.id }))}
            onChange={(id) => {
              form.setFieldValue('company_id', id)
            }}
          />
        </Form.Item>
      </Label>
    )}

    <Form.Item name='gender'>
      <Radio.Group>
        <Radio value={'M'}>M.</Radio>
        <Radio value={'F'}>Mme.</Radio>
      </Radio.Group>
    </Form.Item>

    <div className="flex gap-4">
      <Label label={"Nom"} icon={light('user')}>
        <Form.Item name={"last_name"}>
          <Input autoFocus disabled={disabled} autoComplete="off" />
        </Form.Item>
      </Label>

      <Label label={"Prénom"} icon={light('user')}>
        <Form.Item name={"first_name"}>
          <Input autoFocus disabled={disabled} autoComplete="off" />
        </Form.Item>
      </Label>
    </div>

    <Label label={"Email"} icon={light('envelope')}>
      <Form.Item
        name={"email"}
        rules={[
          {
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: t("rules.email-valid"),
          },
        ]}
      >
        <Input autoFocus disabled={disabled} autoComplete="off" />
      </Form.Item>
    </Label>

    {type !== 'transcribers' && <>
      {type === 'administrators' && <Label label={"Recevoir une notification lorsque une transcription est créée."} icon={light('inbox-in')} isSwitch>
        <Form.Item
          name={"notification_receipt"}
          valuePropName="checked"
        >
          <Switch disabled={disabled} />
        </Form.Item>
      </Label>}
      <Label label={"Recevoir une notification lorsque une transcription est complétée."} icon={light('inbox-out')} isSwitch>
        <Form.Item
          name={"notification_report"}
          valuePropName="checked"
        >
          <Switch disabled={disabled} />
        </Form.Item>
      </Label>
    </>}

    {type === 'doctors' && universus && <Collapse
      bordered={false}
      size="small"
      items={[{
        key: '1', forceRender: true, label: <Text className="flex gap-1.5 items-center"><FontAwesomeIcon icon={regular("cog")} /> Configurations</Text>, children: <div className='flex flex-col gap-1.5'>
          <Label label={"Spécialités"} icon={light('shapes')}>
            <Form.Item name='specialities'>
              <Select
                allowClear
                mode={"default"}
                options={specialities.map((item) => ({ label: item.name, value: item.id }))}
              />
            </Form.Item>
          </Label>

          <Label label={"Contexte pour le modèle"} icon={light('chart-radar')}>
            <Form.Item
              name={"prompt"}
            >
              <Textarea disabled={disabled} />
            </Form.Item>
          </Label>

          <Label label={"Langues"} icon={light('language')}>
            <Form.Item name='languages'>
              <Select
                allowClear
                mode="multiple"
                options={[{ label: "Français", value: "FR" }, { label: "Anglais", value: "EN" }]}
              />
            </Form.Item>
          </Label>

          <Label label={"Configuration audio"} icon={light('microphone-lines')}>
            <Form.Item name={"audio_settings"}>
              <Input autoFocus disabled={disabled} autoComplete="off" />
            </Form.Item>
          </Label>
        </div>
      }]}
    />}

    {type === 'transcribers' && <>
      <Collapse
        bordered={false}
        size="small"
        items={[{
          key: '1', forceRender: true, label: <Text className="flex gap-1.5 items-center"><FontAwesomeIcon icon={regular("toggle-on")} /> Options</Text>, children: <div className='flex flex-col gap-1.5'>
            <Label isStrong label="Activer la transcriptrice." icon={solid('badge-check')} isSwitch>
              <Form.Item
                name={"enable"}
                valuePropName="checked"
              >
                <Switch disabled={disabled} />
              </Form.Item>
            </Label>

            <Label isStrong label="Dossiers à corriger." icon={light('magnifying-glass')} isSwitch>
              <Form.Item
                name={"review"}
                valuePropName="checked"
              >
                <Switch disabled={disabled} />
              </Form.Item>
            </Label>

            <Label isStrong label="Dossiers à valider." icon={light('eye')} isSwitch>
              <Form.Item
                name={"validation"}
                valuePropName="checked"
              >
                <Switch disabled={disabled} />
              </Form.Item>
            </Label>

            <Label isStrong label="Revue Dr Wav obligatoire." icon={light('chart-radar')} isSwitch>
              <Form.Item
                name={"review_wav"}
                valuePropName="checked"
              >
                <Switch disabled={disabled} />
              </Form.Item>
            </Label>

            <Label isStrong label="Génération de rapports." icon={light('sparkles')} isSwitch>
              <Form.Item
                name={"generator"}
                valuePropName="checked"
              >
                <Switch disabled={disabled} />
              </Form.Item>
            </Label>
          </div>
        }]}
      />


      <Collapse
        bordered={false}
        size="small"
        items={[{
          key: '1', forceRender: true, label: <Text className="flex gap-1.5 items-center"><FontAwesomeIcon icon={regular("sliders")} /> Filtres</Text>, children: <div className='flex flex-col gap-1.5'>
            <Label label={"Services"} icon={light('circle-dot')}>
              <Form.Item name='jobs'>
                <Select
                  allowClear
                  mode={"multiple"}
                  options={[
                    { label: "Transcriptions", value: "transcribe" },
                    { label: "Corrections", value: "correction" },
                    { label: "Traductions", value: "translate" }
                  ]}
                />
              </Form.Item>
            </Label>

            <Label label={"Médecins"} icon={light('user-doctor')}>
              <Form.Item name='doctors'>
                <Select
                  allowClear
                  mode={"multiple"}
                  options={companies.map(c => ({ label: c.name, title: c.name, options: doctors.filter(d => d.company_id === c.id).map((item) => ({ label: item.profile.last_name + ' ' + item.profile.first_name, value: item.id })) }))}
                />
              </Form.Item>
            </Label>

            <Label label={"Cliniques"} icon={light('hospital')}>
              <Form.Item name='clinics'>
                <Select
                  allowClear
                  mode={"multiple"}
                  options={companies.map((item) => ({ label: item.name, value: item.id }))}
                />
              </Form.Item>
            </Label>

            <Label label={"Spécialités"} icon={light('shapes')}>
              <Form.Item name='specialities'>
                <Select
                  allowClear
                  mode={"multiple"}
                  options={specialities.map((item) => ({ label: item.name, value: item.id }))}
                />
              </Form.Item>
            </Label>

            <Label label={"Urgences"} icon={light('siren-on')}>
              <Form.Item name='emergency'>
                <Select
                  options={[
                    { label: "Afficher les dossiers urgents", value: true },
                    { label: "Cacher les dossiers urgents", value: false },
                  ]}
                />
              </Form.Item>
            </Label>

            <Label label={"Durée maximum"} icon={light('gauge')} info={"0 applique aucun filtre a la durée"}>
              <Form.Item name='duration'>
                <Input type="number" />
              </Form.Item>
            </Label>

            <Label label={"Langues"} icon={light('language')}>
              <Form.Item name='languages'>
                <Select
                  allowClear
                  mode="multiple"
                  options={[{ label: "Français", value: "fr" }, { label: "Anglais", value: "en" }]}
                />
              </Form.Item>
            </Label>
          </div>
        }]}
      />

      <Collapse
        bordered={false}
        size="small"
        items={[{
          key: '1', forceRender: true, label: <Text className="flex gap-1.5 items-center"><FontAwesomeIcon icon={regular("key")} /> Permissions</Text>, children: <div className='flex flex-col gap-1.5'>
            <Form.Item
              name={["permissions", "update-folder"]}
              valuePropName="checked"
            >
              <Checkbox disabled={disabled}>
                Modifier un dossier
              </Checkbox>
            </Form.Item>

            <Form.Item
              name={["permissions", "create-folder"]}
              valuePropName="checked"
            >
              <Checkbox disabled={disabled}>
                Créer un dossier
              </Checkbox>
            </Form.Item>


            <Form.Item
              name={["permissions", "delete-folder"]}
              valuePropName="checked"
            >
              <Checkbox disabled={disabled}>
                Supprimer un dossier
              </Checkbox>
            </Form.Item>

            <div className='flex items-center gap-1'>
              <Form.Item
                name={["permissions", "read-folders"]}
                valuePropName="checked"
              >
                <Checkbox disabled={disabled}>
                  Administrateur
                </Checkbox>
              </Form.Item>
              <Tooltip title={"Dossiers en cours"}>
                <FontAwesomeIcon className='text-description' icon={light('circle-info')} />
              </Tooltip>
            </div>

            <div className='flex items-center gap-1'>
              <Form.Item
                name={["permissions", "admin"]}
                valuePropName="checked"
              >
                <Checkbox disabled={disabled}>
                  Super-administrateur
                </Checkbox>
              </Form.Item>
              <Tooltip title={"Cliniques, Médecins, Transcriptrices"}>
                <FontAwesomeIcon className='text-description' icon={light('circle-info')} />
              </Tooltip>
            </div>
          </div>
        }]}
      />

      <Collapse
        bordered={false}
        size="small"
        items={[{
          key: '1', forceRender: true, label: <Text className="flex gap-1.5 items-center"><FontAwesomeIcon icon={regular("cog")} /> Configurations</Text>, children: <div className='flex flex-col gap-1.5'>
            <Label label={"ID Rocketchat"} icon={light('rocket')}>
              <Form.Item name={"rocketchat_id"}>
                <Input autoFocus disabled={disabled} autoComplete="off" />
              </Form.Item>
            </Label>

            <Label label={"Prix à la minute"} icon={light('timer')}>
              <Form.Item name={"minute_price"}>
                <Input autoFocus disabled={disabled} autoComplete="off" type={'number'} />
              </Form.Item>
            </Label>
          </div>
        }]}
      />
    </>}

    {!update &&
      <Label isStrong label={`Inviter ${type === 'administrators' ? "l'administrateur" : type === 'doctors' ? 'le médecin' : 'la transcriptrice'} a utiliser l'application universus`} icon={light('envelope-open-text')} isSwitch>
        <Form.Item
          name={"create_user"}
          valuePropName="checked"
        >
          <Switch disabled={disabled} />
        </Form.Item>
      </Label>
    }
  </>
}

const createUser = async (values, type, payload) => {
  const { data, error } = await supabase.from('profiles').insert({
    first_name: values.first_name,
    last_name: values.last_name,
    gender: values.gender,
    email: values.email?.trim().length ? values.email : null,
    avatar: values.avatar,
    role: type.slice(0, -1),
    company_id: values.company_id,
    universus: type === 'transcribers' ? true : false
  }).select('*, company:companies(*)').single()

  if (error) {
    return { error }
  }

  const { data: dataSettings, error: errorSettings } = await supabase
    .from("profile_settings")
    .insert({
      profile_id: data.id,
      notification_receipt: values.notification_receipt,
      notification_report: values.notification_report,
      permissions: values.permissions ? values.permissions : {
        "create-folder": true,
        "delete-folder": true,
        "update-folder": true,
        "read-folders": true,
        "admin": type === 'administrators'
      }
    })
    .select("*");

  if (errorSettings) {
    return { error: errorSettings }
  }

  const { data: dataUser, error: errorUser } = await supabase.from(type).insert({
    profile_id: data.id,
    company_id: values.company_id,
    ...payload.reduce((acc, key) => {
      let value
      if (key === "audio_settings") {
        value = JSON.parse(!values[key] ? kits.doctors.initialValues.audio_settings : values[key])
      } else {
        value = key === "specialities" && type === "doctors" ? values[key] ? [values[key]] : [] : values[key]
      }

      return {
        ...acc,
        [key]: value
      }
    }, {})
  }).select('*').single()

  if (errorUser) {
    return { error: errorUser }
  }

  if (type === 'transcribers') {
    const { error: errorTranscriber } = await supabase.functions.invoke('create-transcriber', {
      body: {
        profile_id: data.id,
      }
    })

    
    if (errorTranscriber) {
      return { error: errorTranscriber }
    }
  }

  return {
    ...dataUser,
    company: data.company,
    profile: {
      ...data,
      profile_settings: dataSettings
    },
    create_user: values.create_user,
  }
}

const updateUser = async (values, item, type, payload) => {
  const { data, error } = await supabase.from('profiles').update({
    first_name: values.first_name,
    gender: values.gender,
    last_name: values.last_name,
    email: values.email?.trim().length ? values.email : null,
    avatar: values.avatar
  }).eq('id', item.profile.id).select('*, company:companies(*)').single()

  if (error) {
    return { error }
  }

  const { data: dataSettings, error: errorSettings } = await supabase
    .from("profile_settings")
    .update({
      profile_id: data.id,
      notification_receipt: values.notification_receipt,
      notification_report: values.notification_report,
      ...(values.permissions ? { permissions: values.permissions } : {})
    })
    .eq("profile_id", data.id)
    .select("*")

  if (errorSettings) {
    return { error: errorSettings }
  }

  const { data: dataUser, error: errorUser } = await supabase.from(type).update({
    ...payload.reduce((acc, key) => ({ ...acc, [key]: ['audio_settings'].includes(key) ? JSON.parse(values[key]) : key === "specialities" && type === "doctors" ? values[key] ? [values[key]] : [] : values[key] }), {}),
    id: item.id
  }).eq('id', item.id).select('*').single()

  if (errorUser) {
    return { error: errorUser }
  }

  if (values.email !== item.profile.email && item.profile.user_id) {
    const { error: errorEmail } = await supabase.functions.invoke('update-email', {
      body: {
        user_id: item.profile.user_id,
        email: values.email
      }
    })

    if (errorEmail) {
      return { error: errorEmail }
    }
  }

  return {
    id: item.id,
    ...dataUser,
    company: data.company,
    profile: {
      ...data,
      profile_settings: dataSettings,
    }
  }
}

const kits = {
  'transcribers': {
    header: 'Équipe',
    headerInsert: 'Nouvelle transcriptrice',
    headerUpdate: 'Transcriptrice',
    initialValues: {
      notification_receipt: false,
      notification_report: true,
      minute_price: 0,
      level: 0,
      rocketchat_id: '',
      avatar: '',
      gender: 'M',
      first_name: '',
      emergency: false,
      duration: 0,
      specialities: [],
      last_name: '',
      review: true,
      validation: true,
      review_wav: true,
      generator: true,
      enable: true,
      languages: ["fr"],
      doctors: [],
      clinics: [],
      jobs: ["transcribe"],
      email: '',
      permissions: {
        "create-folder": false,
        "delete-folder": false,
        "update-folder": true,
        "read-folders": false,
        "admin": false
      },
      create_user: true
    },
    searchField: (item) => item.profile?.last_name + ' ' + item.profile?.first_name + ' ' + item.profile?.email,
    get: () => supabase.from('transcribers').select('id, emergency, duration, languages, doctors, jobs, enable, clinics, review, validation, review_wav, generator ,specialities, rocketchat_id, level, minute_price, profile:profiles(last_sign_in_at, user_id, first_name, last_name, avatar, email, id, gender, profile_settings(*))').eq('enable', true).order('profile(last_name)', { ascending: true }),
    formatItem: (item) => ({
      ...item,
      ...item.profile.profile_settings[0],
      ...item.profile
    }),
    create: (values) => createUser(values, 'transcribers', ['emergency', 'duration', 'languages', 'level', 'minute_price', 'rocketchat_id', 'specialities', 'doctors', 'jobs', 'clinics', 'review', 'validation', 'review_wav', 'generator', 'enable']),
    update: (values, item) => updateUser(values, item, 'transcribers', ['emergency', 'duration', 'languages', 'level', 'minute_price', 'rocketchat_id', 'specialities', 'doctors', 'jobs', 'clinics', 'review', 'validation', 'review_wav', 'generator', 'enable']),
    title: (d, universus) => userTitle(d, universus),
    icon: duotone('people-group'),
    icons: {
      solid: solid('people-group'),
      regular: regular('people-group'),
      light: light('people-group')
    },
    form: ({ doctors, disabled, companies, specialities, universus, update, form, type, t }) => userForm({ t, doctors, disabled, companies, specialities, universus, update, form, type })
  },
  'doctors': {
    header: 'Médecins',
    headerInsert: 'Nouveau médecin',
    headerUpdate: 'Medecin',
    initialValues: {
      notification_receipt: false,
      notification_report: true,
      languages: ["FR"],
      audio_settings: '{"tempo": 1, "silence": {"enabled": true, "stop_threshold": -35}}',
      specialities: null,
      avatar: '',
      gender: 'M',
      first_name: '',
      last_name: '',
      prompt: '',
      email: '',
      create_user: true,
      company_id: null
    },
    searchField: (item) => item.company?.name + ' ' + item.profile?.last_name + ' ' + item.profile?.first_name + ' ' + item.profile?.email,
    get: () => supabase.from('doctors').select('id, specialities, prompt, languages, audio_settings, profile:profiles(last_sign_in_at, user_id, first_name, last_name, avatar, email, id, gender, profile_settings(*)), company:companies(*)').order('profile(last_name)', { ascending: true }),
    formatItem: (item) => ({
      ...item.profile,
      ...item.profile.profile_settings[0],
      specialities: item.specialities.length ? item.specialities[0] : null,
      prompt: item.prompt,
      languages: item.languages,
      audio_settings: JSON.stringify(item.audio_settings)
    }),
    create: (values) => createUser(values, 'doctors', ['audio_settings', 'languages', 'prompt', 'specialities']),
    update: (values, item) => updateUser(values, item, 'doctors', ['audio_settings', 'languages', 'prompt', 'specialities']),
    title: (d, universus) => userTitle(d, universus),
    icon: duotone('user-doctor'),
    icons: {
      solid: solid('user-doctor'),
      regular: regular('user-doctor'),
      light: light('user-doctor')
    },
    form: ({ disabled, companies, specialities, universus, update, form, type, t }) => userForm({ t, disabled, companies, specialities, universus, update, form, type })
  },
  'administrators': {
    header: 'Administrateurs',
    headerInsert: 'Nouveau administrateur',
    headerUpdate: 'Administrateur',
    initialValues: {
      notification_receipt: false,
      notification_report: true,
      gender: 'M',
      avatar: '',
      first_name: '',
      last_name: '',
      email: '',
      create_user: true,
      company_id: null
    },
    searchField: (item) => item.company?.name + ' ' + item.profile?.last_name + ' ' + item.profile?.first_name + ' ' + item.profile?.email,
    get: () => supabase.from('administrators').select('id, profile:profiles(id, last_sign_in_at, user_id, first_name, last_name, avatar, email, id, gender, profile_settings(*)), company:companies(*)').order('profile(last_name)', { ascending: true }),
    formatItem: (item) => ({
      ...item.profile,
      ...item.profile.profile_settings[0],
    }),
    create: (values) => createUser(values, 'administrators', []),
    update: (values, item) => updateUser(values, item, 'administrators', []),
    title: (d, universus) => userTitle(d, universus),
    icon: duotone('user-headset'),
    icons: {
      solid: solid('user-headset'),
      regular: regular('user-headset'),
      light: light('user-headset')
    },
    form: ({ disabled, companies, universus, update, form, type, t }) => userForm({ t, disabled, companies, universus, update, form, type })
  },
  'companies': {
    header: 'Cliniques',
    headerInsert: 'Nouvelle clinique',
    headerUpdate: 'Clinique',
    initialValues: {
      name: '',
      logo: null,
      docx_format: '{}',
      invoice_unit_price: 0,
      invoice_template_path: null,
      invoice_email: '',
      missing_document_emails: "",
    },
    searchField: (item) => item.name,
    get: () => supabase.from('companies').select('id, logo, name, created_at, settings:company_settings(*))').order('name', { ascending: true }),
    formatItem: (item) => ({
      name: item.name,
      logo: item.logo,
      ...item.settings,
      docx_format: JSON.stringify(item.settings.docx_format),
      missing_document_emails: item.settings.missing_document_emails[0],
    }),
    create: async (values) => {
      const { data: dataSettings, error: errorSettings } = await supabase.from('company_settings').insert({
        docx_format: JSON.parse(values.docx_format),
        invoice_unit_price: values.invoice_unit_price,
        invoice_template_path: values.invoice_template_path,
        invoice_email: values.invoice_email,
        missing_document_emails: [values.missing_document_emails],
      }).select('*').single()

      if (errorSettings) {
        return { error: errorSettings }
      }

      const { data, error } = await supabase.from('companies').insert({
        name: values.name,
        logo: values.logo,
        settings_id: dataSettings.id
      }).select('*').single()

      if (error) {
        return { error }
      }


      return {
        ...data,
        settings: dataSettings
      }
    },
    update: async (values, item) => {
      const { data, error } = await supabase.from('companies').update({
        name: values.name,
        logo: values.logo,
      }).eq('id', values.id).select('*').single()

      if (error) {
        return { error }
      }

      const { data: dataSettings, error: errorSettings } = await supabase.from('company_settings').update({
        docx_format: JSON.parse(values.docx_format),
        invoice_unit_price: values.invoice_unit_price,
        invoice_template_path: values.invoice_template_path,
        invoice_email: values.invoice_email,
        missing_document_emails: [values.missing_document_emails],
      }).eq('id', item.settings.id).select('*').single()

      if (errorSettings) {
        return { error: errorSettings }
      }

      return {
        ...data,
        settings: dataSettings
      }
    },
    title: (d) => d.name,
    icon: duotone('hospital'),
    icons: {
      solid: solid('hospital'),
      regular: regular('hospital'),
      light: light('hospital')
    },
    form: ({ disabled, form }) => {
      return <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
          <Form.Item name='logo' style={{ display: 'none' }}><Input /></Form.Item>
          <CompanyLogo form={form} disabled={disabled} />
        </div>

        <Label label={"Nom"} icon={light('pencil')}>
          <Form.Item name={"name"}>
            <Input autoFocus disabled={disabled} autoComplete="off" />
          </Form.Item>
        </Label>

        <Collapse
          bordered={false}
          size="small"
          items={[{
            key: '1', forceRender: true, label: <Text className="flex gap-1.5 items-center"><FontAwesomeIcon icon={regular("cog")} /> Configurations</Text>, children: <div className='flex flex-col gap-1.5'>
              <Label label={"Format .docx"} icon={light('file-doc')}>
                <Form.Item name={"docx_format"}>
                  <Input autoFocus disabled={disabled} autoComplete="off" />
                </Form.Item>
              </Label>
              <Label label={"Email pour les documents manquants"} icon={light('messages-question')}>
                <Form.Item name={"missing_document_emails"}>
                  <Input autoFocus disabled={disabled} autoComplete="off" />
                </Form.Item>
              </Label>
            </div>
          }]}
        />

        <Collapse
          bordered={false}
          size="small"
          items={[{
            key: '1', forceRender: true, label: <Text className="flex gap-1.5 items-center"><FontAwesomeIcon icon={regular("file-invoice-dollar")} /> Facturation</Text>, children: <div className='flex flex-col gap-1.5'>
              <Label label={"Prix unitaire"} icon={light('dollar')}>
                <Form.Item name={"invoice_unit_price"}>
                  <Input autoFocus disabled={disabled} autoComplete="off" />
                </Form.Item>
              </Label>

              <Label label={"Email d'envoie des factures"} icon={light('envelope')}>
                <Form.Item name={"invoice_email"}>
                  <Input autoFocus disabled={disabled} autoComplete="off" />
                </Form.Item>
              </Label>

              <Label label={"Gabarit"} icon={light('file')}>
                <Form.Item name='invoice_template_path' style={{ display: 'none' }}><Input /></Form.Item>
                <InvoiceTemplate form={form} disabled={disabled} />
              </Label>
            </div>
          }]}
        />
      </>
    }
  },
  'specialities': {
    header: 'Spécialités',
    headerInsert: 'Nouvelle spécialité',
    headerUpdate: 'Spécialité',
    initialValues: {
      name: '',
      prompt: '',
    },
    searchField: (item) => item.name,
    get: () => supabase.from('specialities').select('id, name, prompt').order('name', { ascending: true }),
    formatItem: (item) => ({
      name: item.name,
      prompt: item.prompt,
    }),
    create: async (values) => {
      const { data, error } = await supabase.from('specialities').insert({
        name: values.name,
        prompt: values.prompt,
      }).select('*').single()

      if (error) {
        return { error }
      }

      return {
        ...data,
      }
    },
    update: async (values, item) => {
      const { data, error } = await supabase.from('specialities').update({
        name: values.name,
        prompt: values.prompt,
      }).eq('id', values.id).select('*').single()

      if (error) {
        return { error }
      }

      return {
        ...data,
      }
    },
    title: (d) => d.name,
    icon: duotone('shapes'),
    icons: {
      solid: solid('shapes'),
      regular: regular('shapes'),
      light: light('shapes')
    },
    form: ({ disabled }) => {
      return <>
        <Label label={"Nom"} icon={light('pencil')}>
          <Form.Item name={"name"}>
            <Input autoFocus disabled={disabled} autoComplete="off" />
          </Form.Item>
        </Label>
        <Label label={"Contexte pour le modèle"} icon={light('chart-radar')}>
          <Form.Item
            name={"prompt"}
          >
            <Textarea disabled={disabled} />
          </Form.Item>
        </Label>
      </>
    }
  }
}

export default function SettingsUsers({ type }) {
  const { t } = useTranslation()
  const { darkMode, messageApi } = useUI()
  const { token } = useToken()
  const [form] = Form.useForm()
  const [valuesChange, setValuesChange] = useState({})
  const [items, setItems] = useState([])
  const [update, setUpdate] = useState(null)
  const [initialValues, setInitialValues] = useState({})
  const [filterCompany, setFilterCompany] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [search, setSearch] = useState('')
  const [companyInvoices, setCompanyInvoice] = useState(null)
  const [loading, setLoading] = useState(true)
  const [kit, setKit] = useState({})
  const { session } = useAuth()
  const { data: companies } = useCompanies()
  const { data: specialities, refetch: refreshSpecialities } = useSpecialities()
  const { data: doctors } = useDoctors()
  const { data: profile } = useProfile()

  const universus = session.user.user_metadata.universus

  useEffect(() => {
    setLoading(true)
    setItems([])
    setKit(null)

    const getItems = async () => {
      const kit = kits[type]
      setKit(kit)

      setInitialValues({
        ...kit.initialValues,
        ...(kit.initialValues.company_id && !universus ? {
          company_id: session.user.user_metadata.company_id
        } : ['administrators', 'doctors'].includes(type) && filterCompany ? { company_id: filterCompany } : {})
      })

      const query = kit.get()

      if (['administrators', 'doctors'].includes(type) && filterCompany) {
        query.eq("company_id", filterCompany)
      }

      if (type !== 'transcribers') {
        query.is('deleted_at', null)
      }

      const { data, error } = await query

      if (type === "administrators") {
        setItems(data.filter(administrator => administrator.profile.id !== profile.id))
      } else {
        setItems(data)
      }

      setLoading(false)
    }

    getItems()
  }, [type, filterCompany])


  const [visible, setVisible] = useState(false)

  const inviteUser = async (profile_id) => supabase.functions.invoke('invite-user', {
    body: {
      type,
      profile_id
    }
  })

  const onFinish = async (values) => {
    if (values.create_user && !isEmail(values.email)) {
      messageApi.open({
        type: 'error',
        content: 'Une adresse email valide est requise pour inviter un utilisateur.',
        duration: 4,
      })

      return;
    }

    setDisabled(true)
    let r
    if (update) {
      r = await kit.update({ id: update.id, ...values }, update)
    } else {
      r = await kit.create(values)
    }

    if (r.error) {
      let message = r.error.message

      if (message.includes('duplicate')) {
        message = t('email-exists')
      }

      messageApi.open({
        type: 'error',
        content: message,
        duration: 4,
      })
      setDisabled(false)
    } else {
      if (type === 'specialities') {
        refreshSpecialities()
      }

      if (update) {
        setItems(items.map(i => i.id === r.id ? { ...i, ...r } : i))
      } else {
        if (r.create_user) {
          await inviteUser(r.profile.id)
        }

        setItems([r, ...items])
      }

      setDisabled(false)
      onClose()
    }
  }

  const inviteUserFromList = async (item) => {
    if (!isEmail(item.profile.email)) {
      messageApi.open({
        type: 'error',
        content: 'Une adresse email valide est requise pour inviter un utilisateur.',
        duration: 4,
      })

      return false
    }


    setItems(items.map(i => i.id === item.id ? { ...i, create_user: 'loading' } : i))
    await inviteUser(item.profile.id)
    setTimeout(() => {
      setItems(items.map(i => i.id === item.id ? { ...i, create_user: true } : i))
    }, 1000)
  }

  const onClose = () => [setVisible(false), setUpdate(null)]

  const datas = useMemo(() => {
    return items.filter(item => kit.searchField(item).toLowerCase().includes(search.toLowerCase()))
  }, [search, items, type])

  return (
    <div style={{
      flexGrow: 1,
      display: "flex",
      height: "100%",
      boxSizing: "border-box"
    }}>
      <List
        loading={loading}
        style={{
          maxHeight: "100%",
          overflow: "auto",
          width: "100%",
          borderRadius: "0px",
          border: "none"
        }}
        className='scroll universus-management'
        header={<div className='bg-container' style={{
          display: 'flex', alignItems: 'center', position: "sticky",
          top: "0px",
          left: "0px",
          width: "100%"
        }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            {kit.icon && <FontAwesomeIcon icon={kit.icon} size={"lg"} />}
            <Text style={{ fontSize: 16 }}>{kit.header}</Text>
          </div>
          <div className='flex items-center gap-2 ml-auto'>
            {universus && ['doctors', 'administrators'].includes(type) && <Select
              size="medium"
              style={{ width: 150 }}
              showSearch={true}
              allowClear
              value={filterCompany}
              placeholder="Clinique"
              onChange={(value) => setFilterCompany(value)}
              options={companies.map(c => ({ label: c.name, value: c.id }))}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(
                    input
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase()
                  )
              }
            />}
            <InputAnt placeholder='Rechercher...' style={{ width: 150 }} value={search} onChange={(e) => setSearch(e.target.value)} />
            <Button onClick={() => [form.setFieldsValue(initialValues), setVisible(true)]} icon={<FontAwesomeIcon icon={solid('plus')} />}>Ajouter</Button>
          </div>
        </div>}
        bordered
        dataSource={datas}
        renderItem={(item) => (
          <List.Item onClick={() => [setUpdate(item), form.setFieldsValue(kit.formatItem(item)), setVisible(true)]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {kit.title(item, universus)}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: "center" }}>
              {<>
                {type !== 'companies' && item.profile && <>
                  {!item.profile.user_id && !item.profile.last_sign_in_at && (!item.create_user || item.create_user === 'loading') && <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "6px"
                  }}>
                    <Button loading={item.create_user === 'loading'} onClick={(e) => [e.preventDefault(), e.stopPropagation(), inviteUserFromList(item)]} type="dashed" icon={<FontAwesomeIcon icon={solid('envelope-open-text')} />}>Envoyer une invitation</Button>
                  </div>}
                  {item.profile.last_sign_in_at && <Tooltip title="Dernière connexion" placement='left'><Text type="secondary" style={{ display: 'flex', alignItems: 'center', gap: 6, fontSize: 12 }}><FontAwesomeIcon icon={light('key')} /> {DateTime.fromISO(item.profile.last_sign_in_at).toFormat('f')}</Text></Tooltip>}
                  {!item.profile.last_sign_in_at && (item.create_user === true || item.profile.user_id) && <Text style={{ display: 'flex', alignItems: 'center', gap: 6 }} type="success" strong><FontAwesomeIcon icon={solid('envelope-circle-check')} /> Invitation envoyée</Text>}
                </>}
                <>
                  {type === "transcribers" && item.profile && item.profile.last_sign_in_at && item.profile.user_id && <Button icon={<FontAwesomeIcon icon={solid('chart-user')} />}>Performances</Button>}
                  {(type === "companies" || (item.profile && type === "transcribers" && item.profile.last_sign_in_at && item.profile.user_id)) && <Button onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    if (type === 'companies') {
                      setCompanyInvoice(item.id)
                    }
                  }} icon={<FontAwesomeIcon icon={solid('file-invoice-dollar')} />}>Factures</Button>}
                </>
              </>}
            </div>

          </List.Item>
        )}
      />

      <CompanyInvoices company_id={companyInvoices} close={() => setCompanyInvoice(null)} />

      <Modal
        styles={{
          content: {
            padding: 0,
            background: 'transparent',
            overflow: 'hidden',
            borderRadius: 6,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
            border: '1px solid ' + token.colorBorderLayout,
            background: token.colorBgContainer,
          },
        }}
        width={600}
        closeIcon={false}
        footer={null}
        centered
        open={visible}
        onCancel={onClose}
      >
        {visible && <Form
          onValuesChange={(values) => setValuesChange(values)}
          className="flex h-full flex-col"
          disabled={disabled}
          validateTrigger={'onBlur'}
          form={form}
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <div className="relative bottom-[1px] flex items-center justify-between border-0 border-b border-solid border-layout bg-layout py-2 pl-4 pr-1">
            <Text type="secondary" className="inline-flex cursor-pointer select-none items-center gap-2">
              <FontAwesomeIcon icon={kit.icons.solid} />
              {update ? kit.headerUpdate : kit.headerInsert}
            </Text>
            <Button onClick={onClose} type="text">
              {t(`cancel`)}
            </Button>
          </div>
          <div className='flex h-full flex-col flex-grow scroll relative overflow-auto'>
            <div className='w-full'>
              <div className="relative w-full">
                <div className={clsx('w-full', 'overflow-auto scroll')} style={{ maxHeight: '80vh' }}>
                  <div className="box-border flex w-full flex-col gap-3 px-6 py-4">
                    {kit.form({ t, doctors, form, disabled, universus, companies, update, type, specialities })}
                    <div className="relative box-border flex flex w-full justify-between gap-1.5 border-0 border-layout py-2 items-center">
                      <div></div>
                      <Button loading={disabled} htmlType="submit" type="primary">
                        {t(`form.${update ? 'update' : 'create'}`)}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>}
      </Modal>
    </div>
  )
}

function isEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

function CompanyLogo({ form, disabled }) {
  const fileInputRef = useRef(null)
  const { setLogoPreview, logoPreview } = useUI()
  const [isLoading, setIsLoading] = useState(false)

  const logo = Form.useWatch('logo', form)

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    const fileExtension = file.name.split('.').pop()
    const fileName = `${new Date().getTime()}.${fileExtension}`

    setIsLoading(true)

    const { error } = await supabase.storage.from('logos').upload(fileName, file, {
      cacheControl: '3600',
      upsert: true,
    })

    if (error) {
      messageApi.open({
        type: 'error',
        content: error.message,
        duration: 4,
      })
    } else {
      const {
        data: { publicUrl },
      } = await supabase.storage.from('logos').getPublicUrl(fileName, 60)

      form.setFieldValue('logo', publicUrl)
    }

    setIsLoading(false)
  }

  return <div className="flex items-center gap-3 w-full">
    <input ref={fileInputRef} id="fileInput" type="file" style={{ display: 'none' }} onChange={handleFileChange} />
    <Button onClick={() => fileInputRef.current.click()} disabled={disabled} loading={isLoading} icon={<FontAwesomeIcon icon={solid('image')} />} shape={"circle"} />
    {logo && <img style={{ height: 44 }} src={logo} />}
    {logo && <Button className="ml-auto" onClick={() => setLogoPreview(logoPreview ? null : logo)} icon={<FontAwesomeIcon icon={solid('eye')} />} shape={"circle"} />}
  </div>
}

function InvoiceTemplate({ form, disabled }) {
  const fileInputRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const { messageApi } = useUI()

  const template = Form.useWatch('invoice_template_path', form)

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    const fileExtension = file.name.split('.').pop()
    const fileName = `${new Date().getTime()}.${fileExtension}`

    setIsLoading(true)

    const { error, data: { path } } = await supabase.storage.from('templates_invoice').upload(fileName, file, {
      cacheControl: '3600',
      upsert: true,
    })

    console.log(error)

    if (error) {
      messageApi.open({
        type: 'error',
        content: error.message,
        duration: 4,
      })
    } else {
      form.setFieldValue('invoice_template_path', path)
    }

    setIsLoading(false)
  }

  return <div className="flex items-center gap-3 w-full">
    <input ref={fileInputRef} accept=".xlsx" id="fileInput" type="file" style={{ display: 'none' }} onChange={handleFileChange} />
    <Button onClick={() => fileInputRef.current.click()} disabled={disabled} loading={isLoading} icon={<FontAwesomeIcon icon={solid('upload')} />}>Modifier</Button>
    {template && <Typography.Text>{template}</Typography.Text>}
  </div>
}